
import Vue from 'vue'
import { component as Viewer } from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
@Component({
    components: { Viewer },
    filters: {
        //局部过滤器
    },
})
export default class ClassComponent extends Vue {
    viewer: any = null
    images: any[] = []
    // 插件配置项
    options: any = {
        hide: (event: any) => {
            this.$emit('previewHide')
        },
    }

    @Prop({ type: Array, default: () => [] })
    imgs!: any[]

    @Prop({ default: '' })
    showId!: string | number

    inited(viewer: any) {
        this.viewer = viewer
    }

    @Watch('showId')
    toshow(id: string | number) {
        let imgDoms: any = document.getElementsByClassName(
            'preview-img-item-toclick'
        )

        // alert('122')


        let index: number = this.imgs.findIndex((el: any) => el.id === id) || 0
        if (imgDoms[index]) {
            imgDoms[index].click()
        }
    }
}
